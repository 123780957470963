<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <template
      v-if="mailEditable"
    >
      <div
        class="content_display"
        :style="style"
      >
        <span
          class="ckeditor-mail-label"
        >
          {{ lang('ChecklistBuilder.misc.email.subject') }}
        </span>
        <div
          v-dompurify-html="value.subject"
          :class="`mail-subject${classes}`"
          :disabled="disabled"
          @click="dialogEmail.active = true"
        />
        <br>
        <span
          class="ckeditor-mail-label"
        >
          {{ lang('ChecklistBuilder.misc.email.message') }}
        </span>
        <div
          v-dompurify-html="value.message"
          :class="`mail-message${classes}`"
          :disabled="disabled"
          @click="dialogEmail.active = true"
        />
      </div>
      <v-btn
        type="button"
        small
        :class="`no-clip mt-2 mb-1 ${classes}`"
        :disabled="disabled"
        data-content-button-action="ckeditor"
        data-content-button-type="mail"
        @click="dialogEmail.active = true"
      >
        {{ lang('ChecklistBuilder.misc.open_editor') }}
      </v-btn>
      <dialog-ckeditor-email
        v-if="!disabled"
        v-model="dialogEmail.active"
        :dialog="dialogEmail"
        :title="title"
        :settings="settings"
        :message="value.message"
        :subject="value.subject"
        :subject-title="lang('ChecklistBuilder.misc.email.subject')"
        :text-save-button="lang('ChecklistBuilder.misc.buttons.save')"
        :text-close-button="lang('ChecklistBuilder.misc.buttons.close')"
        @closeckeditoremail="dialogEmail.active = false"
        @saveckeditoremail="onSaveCkeditorEmail"
      />
      <br>
    </template>
    <output
      style="margin: 5px;"
    >
      {{ sendDateLabel }}
    </output>
    <v-btn
      :class="`no-clip ${classes}`"
      :disabled="disabled"
      style="margin: 5px;"
      type="button"
      small
      data-content-button-action="send_email"
      @click="sendEmail"
    >
      {{ buttonLabel }}
    </v-btn>
    <dialog-settings-email
      v-if="!disabled"
      v-model="dialogSettings.active"
      :dialog="dialogSettings"
      :title="title"
      :cell-settings="cellSettings"
      :email-settings="emailSettings"
      :builder="data.ChecklistBuilder"
      :data="data"
      @closesettingsemail="dialogSettings.active = false"
      @savesettingsemail="onSaveSettingsEmail"
    />
  </td>
</template>

<script>
/* eslint-disable no-control-regex */
import DialogCkeditorEmail
  from '@/components/ChecklistBuilder/Dialog/CkeditorEmail.vue'
import DialogSettingsEmail
  from '@/components/ChecklistBuilder/Dialog/SettingsEmail.vue'

export default {
  name: 'ChecklistCellTypeTextEmailFormatted',
  components: {
    DialogCkeditorEmail,
    DialogSettingsEmail
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      settings: {},
      dialogEmail: {
        active: false
      },
      dialogSettings: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    style() {
      const builder = this.data.ChecklistBuilder
      if (builder.getSetting('SHOW_CONTENT_PREVIEW')) {
        return ''
      }
      return 'display: none;'
    },
    mailEditable() {
      return !!this.cell.settings.email_text_editable
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone)
      let valueDefault = {}
      if (typeof value === 'string') {
        try {
          value = JSON.parse(value)
        } catch (ex) {
          value = { subject: '', message: '' }
        }
      }
      try {
        valueDefault = JSON.parse(builder.getLocalization(this.cell, 'preset_value'))
      } catch (ex) {
        // Keine Aktion nötig
      }

      return builder.extend()(valueDefault, value)
    },
    sendDateLabel() {
      const label = this.lang('ChecklistBuilder.misc.email.informed_at')
      if (this.value.unsend) {
        return label + this.lang('ChecklistBuilder.misc.email.pending')
      }
      if (!this.dataViewer.done) {
        return label + ' - '
      }
      if (!this.value.send_date) {
        return label + ' - '
      }
      if (!this.value.success) {
        return label + ' - '
      }
      const builder = this.data.ChecklistBuilder
      return label + builder.getDateTime(this.value.send_date)
    },
    buttonLabel() {
      const settings = this.cell.settings || {}
      return settings.email_btn_label || this.lang('ChecklistBuilder.misc.email.btn_default')
    },
    title() {
      return this.lang('ChecklistBuilder.templates.elements.text_email_formatted')
    },
    invalidSettings() {
      const settings = this.cell.settings || {}
      if (
        !settings.emails &&
        !parseInt(settings.email_by_user) &&
        !settings.email_audit_created_by_user_as_recipient &&
        !settings.email_audit_affected_employee_supervisors_as_recipient
      ) {
        return true
      }
      return false
    },
    showSettingsDialog() {
      const settings = this.cell.settings || {}
      if (
        !parseInt(settings.email_by_user) &&
        !settings.email_acknowledgement_editable &&
        !settings.collection_links_select_viewer &&
        !settings.email_generated_document_attachment &&
        !settings.email_download_document_attachment &&
        !settings.email_upload_document_attachment &&
        !settings.email_drawing_attachment
      ) {
        return false
      }
      return true
    },
    cellSettings() {
      const builder = this.data.ChecklistBuilder
      return builder.extend()({}, this.cell.settings || {})
    },
    emailSettings() {
      const builder = this.data.ChecklistBuilder
      return builder.extend()({}, this.value || {})
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    onSaveCkeditorEmail(event) {
      const builder = this.data.ChecklistBuilder
      const value = builder.extend()({}, this.value || {})
      value.message = event.message
      value.subject = event.subject
      const previousValue = this.value
      builder.evalValues({
        index: this.checklist.index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: true,
        value: value,
        previousValue: previousValue
      })
      this.dialogEmail.active = false
    },
    async sendEmail() {
      if (this.invalidSettings) {
        return
      }
      const builder = this.data.ChecklistBuilder
      if (!this.dataViewer || !this.dataViewer.id) {
        const value = builder.extend()({}, this.value || {})
        await builder.evalValues({
          index: this.checklist.index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: this.sendOrShowDialog,
          ignoreAutoSaveSetting: true,
          value: value,
          previousValue: this.value
        })
      } else {
        this.sendOrShowDialog()
      }
    },
    async sendOrShowDialog() {
      if (!this.showSettingsDialog) {
        const builder = this.data.ChecklistBuilder
        const settingsBuilder = builder.getSettings()
        const settings = this.cellSettings
        const value = builder.extend()({}, this.value || {})
        value.unsend = true
        if (
          !(
            value &&
            Array.isArray(value.recipients) &&
            value.recipients.length > 0
          ) &&
          !settings.email_audit_created_by_user_as_recipient &&
          !settings.email_audit_affected_employee_supervisors_as_recipient
        ) {
          value.unsend = false
        }
        const previousValue = this.value
        builder.evalValues({
          index: this.checklist.index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: true,
          value: value,
          previousValue: previousValue
        })
      } else {
        this.dialogSettings.active = true
      }
    },
    onSaveSettingsEmail(event) {
      const builder = this.data.ChecklistBuilder
      const settings = this.cellSettings
      const value = builder.extend()({}, this.value || {})
      value['cell_id'] = this.cell.id
      value.recipients = event.dataEmail.recipients
      value['recipients_ccc'] = event.dataEmail['recipients_ccc']
      value['recipients_bcc'] = event.dataEmail['recipients_bcc']
      value.manual = event.dataEmail.manual
      value.unsend = true
      if (
        !(
          event.dataEmail &&
          Array.isArray(event.dataEmail.recipients) &&
          event.dataEmail.recipients.length > 0
        ) &&
        !settings.email_audit_created_by_user_as_recipient &&
        !settings.email_audit_affected_employee_supervisors_as_recipient
      ) {
        value.unsend = false
      }
      value['custom_sender_email'] = settings['custom_sender_email']
      value['custom_sender_surname'] = settings['custom_sender_surname']
      value['custom_sender_lastname'] = settings['custom_sender_lastname']
      value['custom_sender_current_user'] = settings['custom_sender_current_user']
      value['custom_sender_current_user_reply_to'] = settings['custom_sender_current_user_reply_to']
      value.acknowledgement = settings.acknowledgement
      value['collection_link_id'] = settings['collection_links_select']
      if (settings.email_audit_created_by_user_as_recipient) {
        const id = builder.getReplaceEntry('tbl_au_audits.created_by_user')
        if (id && value.recipients.indexOf(id) === -1) {
          value.recipients.push(id)
        }
      }
      if (settings.email_audit_affected_employee_supervisors_as_recipient) {
        const id = builder.getReplaceEntry('tbl_au_audits.affected_employee')
        if (id && value.recipients.indexOf(id) === -1) {
          value.recipients.push(id)
        }
      }
      const previousValue = this.value
      builder.evalValues({
        index: this.checklist.index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: null,
        ignoreAutoSaveSetting: true,
        value: value,
        previousValue: previousValue
      })
      this.dialogSettings.active = false
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content .acknowledgement_overview,
  .checklist-viewer .element-content .collection_links_overview {
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }


  .theme--dark .checklist-viewer .element-content .mail-subject,
  .theme--dark .checklist-viewer .element-content .mail-message {
    min-height: 20px;
    background-color: #808080;
    border: #ddd solid 1px;
    border-radius: 3px;
    padding: 5px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  .theme--light .checklist-viewer .element-content .mail-subject,
  .theme--light .checklist-viewer .element-content .mail-message {
    min-height: 20px;
    background-color: #fff;
    border: #666 solid 1px;
    border-radius: 3px;
    padding: 5px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
</style>
