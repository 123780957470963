<template>
  <v-dialog
    v-model="modalOpen"
    class="email-settings"
    :fullscreen="$vuetify.breakpoint.xs"
    transition="dialog-bottom-transition"
    width="450"
  >
    <v-card>
      <v-card-title
        v-if="title"
        class="dialog-email-settings-title"
      >
        <h3>
          {{ title }}
        </h3>
      </v-card-title>
      <v-card-text
        class="dialog-email-settings-content"
      >
        <v-autocomplete
          v-model="to"
          :items="options"
          item-text="name"
          item-value="id"
          :label="lang('ChecklistBuilder.misc.email.recipient')"
          autocomplete="on"
          chips
          multiple
          hide-details
          ligth="true"
        >
          <template
            slot="selectionTo"
            slot-scope="dataTo"
          >
            <v-chip
              :selected="dataTo.selected"
              class="chip--select-multi"
              @input="removeItem(to, dataTo.item, 1)"
            >
              {{ dataTo.item.name }}
            </v-chip>
          </template>
          <template
            slot="itemTo"
            slot-scope="dataTo"
          >
            <v-list-tile-content>
              <v-list-tile-title v-dompurify-html="dataTo.item.name" />
            </v-list-tile-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="ccc"
          :items="options"
          item-text="name"
          item-value="id"
          :label="lang('ChecklistBuilder.misc.email.recipient_ccc')"
          autocomplete="on"
          chips
          multiple
          hide-details
          ligth="true"
        >
          <template
            slot="selectionCCC"
            slot-scope="dataCCC"
          >
            <v-chip
              :selected="dataCCC.selected"
              class="chip--select-multi"
              @input="removeItem(ccc, dataCCC.item, 2)"
            >
              {{ dataCCC.item.name }}
            </v-chip>
          </template>
          <template
            slot="itemCCC"
            slot-scope="dataCCC"
          >
            <v-list-tile-content>
              <v-list-tile-title>
                {{ dataCCC.item.name }}
              </v-list-tile-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>

        <v-autocomplete
          v-model="bcc"
          :items="options"
          item-text="name"
          item-value="id"
          :label="lang('ChecklistBuilder.misc.email.recipient_bcc')"
          autocomplete="on"
          chips
          multiple
          hide-details
          ligth="true"
        >
          <template
            slot="selectionBCC"
            slot-scope="dataBcc"
          >
            <v-chip
              :selected="dataBcc.selected"
              class="chip--select-multi"
              @input="removeItem(bcc, dataBcc.item, 3)"
            >
              {{ dataBcc.item.name }}
            </v-chip>
          </template>
          <template
            slot="itemBCC"
            slot-scope="dataBcc"
          >
            <v-list-tile-content>
              <v-list-tile-title v-dompurify-html="dataBcc.item.name" />
            </v-list-tile-content>
          </template>
        </v-autocomplete>

        <template
          v-if="mode === 1 || mode === 3"
        >
          <div class="emails-manual-modify">
            <v-autocomplete
              v-model="manualSelected"
              :items="manualEmails"
              item-text="name"
              item-value="id"
              :label="lang('ChecklistBuilder.misc.email.manual_select')"
              autocomplete="on"
              chips
              hide-details
              ligth="true"
            >
              <template
                slot="selectionManual"
                slot-scope="dataManual"
              >
                <v-chip
                  :selected="dataManual.selected"
                  class="chip--select-multi"
                  @input="manualSelected = null"
                >
                  {{ dataManual.item.name }}
                </v-chip>
              </template>
              <template
                slot="itemManual"
                slot-scope="dataManual"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-dompurify-html="dataManual.item.name" />
                </v-list-tile-content>
              </template>
            </v-autocomplete>
            <v-btn
              type="button"
              class="email-delete-btn"
              @click="deleteManualEntry"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              type="button"
              class="email-add-btn"
              @click="addManualEntry"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <v-text-field
            v-model="name"
            :label="lang('ChecklistBuilder.misc.email.manual_name')"
          />
          <v-text-field
            v-model="email"
            :label="lang('ChecklistBuilder.misc.email.manual_email')"
          />
        </template>
      </v-card-text>
      <v-card-actions
        class="dialog-email-settings-actions"
      >
        <v-spacer />
        <v-btn
          @click="onClickSave"
        >
          {{ lang('ChecklistBuilder.misc.buttons.emails_send') }}
        </v-btn>
        <v-btn
          @click="onClickClose"
        >
          {{ lang('ChecklistBuilder.misc.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapGetters } from 'vuex'
import dialogMixin from '@/mixins/dialogMixin.js'
import { getService } from '@/helpers/feathers'
import { Connection } from '@/offline/connection.enum'

export default {
  name: 'DialogSettingsEmail',
  mixins: [dialogMixin],
  props: [
    'dialog',
    'title',
    'cellSettings',
    'emailSettings',
    'builder',
    'data'
  ],
  data() {
    return {
      to: [],
      ccc: [],
      bcc: [],
      manual: {},
      manualSelected: null,
      name: '',
      email: '',
      employees: [],
      options: []
    }
  },
  computed: {
    /* ...mapGetters({
      // @TODO Nutzung auch ohne Cache ermöglichen.
      employees: 'sync/employees'
    }), */
    mode() {
      return parseInt(this.cellSettings.email_by_user) || 0
    },
    manualEmails() {
      const matches = []
      for (const email in this.manual) {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
          matches.push({
            id: email,
            name: this.manual[email] || email
          })
        }
      }
      return matches.sort((a, b) => a.name.localeCompare(b.name))
    },
    filteredEmployees() {
      const matches = []
      const inactive = this.getSettingValue('inactive', false)
      for (const employee of this.employees) {
        if (!inactive && employee.inactive) {
          continue
        }
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(employee.email)) {
          const personnelNumber = employee.personnelNumber
            ? ` [${employee.personnelNumber}]`
            : ''
          const lastName = employee.lastName || ''
          const firstname = employee.firstName || ''
          matches.push({
            id: employee.id,
            name: `${lastName}, ${firstname} ${personnelNumber}`
          })
        }
      }
      return matches
    }
  },
  async mounted() {
    this.manual = (this.cellSettings.emails || {}).manual || this.emailSettings.manual || {}
    this.to = this.emailSettings.recipients || this.cellSettings.recipients || []
    this.ccc = this.emailSettings.recipients_ccc || this.cellSettings.recipients_ccc || []
    this.bcc = this.emailSettings.recipients_bcc || this.cellSettings.recipients_bcc || []
    this.employees = await this.getEmployees()
    this.options = []
        .concat(this.manualEmails || [], this.filteredEmployees || [])
        .sort((x, y) => {
          if (x.name < y.name) {
            return -1
          }
          if ( x.name > y.name) {
            return 1
          }
          return 0
        })
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    },
    async getEmployees() {
      const serviceOffline = await getService(
        'offline-data/employees',
        Connection.Offline
      )
      // Anzahl der Offline-Mitarbeiter zählen und auf die Daten warten
      let total = 0
      let limit = 0
      await serviceOffline
          .find({$limit: limit})
          .then(
            (result) => {
                total = 'total' in result && typeof result.total === 'number'
                ? result.total
                : 0
                limit = 'limit' in result && typeof result.limit === 'number'
                ? result.limit
                : 0
            }
          )

      // Alle Offline-Mitarbeiter holen
      const promises = []
      for (let i = 0; i < total;) {
        promises.push(serviceOffline.find({
          query: { $offset: i += limit }
        }))
      }

      return Promise
        .all(promises)
        .then(result => {
          return [].concat.apply([], result.map(x => {
            return x.data
          }) || [[]])
        })
        .catch(() => {
          return []
        })
    },
    getSettingValue(setting, defaultValue) {
      if (!this.cellSettings) {
        return defaultValue
      }
      return (this.cellSettings.emails || {})[setting] || defaultValue
    },
    onClickSave() {
      this.$emit('savesettingsemail', {
        dataEmail: {
          recipients: this.to,
          'recipients_ccc': this.ccc,
          'recipients_bcc': this.bcc,
          manual: this.manual
        }
      })
    },
    onClickClose () {
      this.$emit('closesettingsemail')
    },
    removeItem (value, item, type) {
      if (Array.isArray(value)) {
        if (value.length === 1) {
          switch (type) {
            case 1:
              if (
                this.builder.getSetting('EMAIL_KEEP_LAST_EMPLOYEE_RECEIVER')
              ) {
                return
              }
              break
            case 2:
              if (this.builder.getSetting('EMAIL_KEEP_LAST_EMPLOYEE_CC')) {
                return
              }
              break
            case 3:
              if (this.builder.getSetting('EMAIL_KEEP_LAST_EMPLOYEE_CC')) {
                return
              }
              break
          }
        }
        for (let i = 0, entry; (entry = value[i]); i++) {
          if (entry.id === item) {
            value.splice(i, 1)
            break
          }
        }
      }
    },
    deleteManualEntry () {
      const email = (this.manualSelected || {}).id
      if (!email) {
        return
      }
      delete this.manual[this.manualSelected.id]
      this.manualSelected = null
      const temp = this.manual
      this.manual = {}
      this.manual = temp
    },
    addManualEntry () {
      if (!this.name) {
        return
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.email)) {
        return
      }
      if (this.manual[this.email]) {
        return
      }
      this.manual[this.email] = this.name
      const temp = this.manual
      this.manual = {}
      this.manual = temp
      this.email = this.name = ''
    }
  }
}
</script>

<style>
  .dialog-email-settings-title,
  .dialog-email-settings-content,
  .dialog-email-settings-actions {
    font-family: Roboto,sans-serif;
    line-height: 1;
  }
  .dialog-email-settings-content {
    text-align: initial;
  }

  .emails-manual-modify {
    display: flex;
  }

  .dialog-email-settings-content .email-delete-btn,
  .dialog-email-settings-content .email-add-btn {
    min-width: 0px;
    margin: auto 5px;
  }
</style>
